import * as THREE from "three";

/**
 * WebGL API を用いて 3D モデルをキャンバスに描画するクラスです。
 */
export class Renderer {
    readonly #internal: Internal;

    /**
     * 新しい {@link Renderer} インスタンスを初期化します。
     *
     * @param container 描画先の要素
     */
    constructor(container: HTMLDivElement) {
        // 内部データ初期化
        this.#internal = {
            container,
            renderer: createRenderer(container),
        };
    }

    /**
     * 破棄します。
     */
    dispose(): void {
        const { renderer } = this.#internal;

        renderer.dispose();
        renderer.domElement?.remove();
    }

    /**
     * レンダラーの描画エリアサイズをリセットします。
     *
     * 描画先要素のサイズが変化したときに呼んでください。
     */
    resetRenderingSize(): void {
        const { container, renderer } = this.#internal;

        resize(renderer, container);
    }

    /**
     * 描画します。
     *
     * @param scene 描画するシーン
     * @param camera カメラ
     */
    update(scene: THREE.Object3D, camera: THREE.Camera): void {
        const { renderer } = this.#internal;

        renderer.render(scene, camera);
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

/**
 * {@link Renderer} クラスの内部データ
 */
interface Internal {
    /** 描画先要素 */
    readonly container: HTMLDivElement;
    /** レンダラー本体 */
    readonly renderer: THREE.WebGLRenderer;
}

/**
 * 新しい {@link THREE.WebGLRenderer} インスタンスを作成します。
 *
 * @param container 描画先要素
 * @returns 作成したレンダラー
 */
function createRenderer(container: HTMLDivElement): THREE.WebGLRenderer {
    const renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
    });
    renderer.toneMapping = THREE.NoToneMapping;
    renderer.toneMappingExposure = 1;
    resize(renderer, container);

    // 描画先 Canvas 要素を DOM Tree に追加
    container.append(renderer.domElement);

    return renderer;
}

/**
 * レンダラーに描画先のサイズと解像度を設定します。
 *
 * あまりに画素数が多くなると性能が加速度的に悪化していくため、Pixel ratio は最
 * 大 2 に抑えています。
 *
 * @param renderer レンダラー
 * @param container 描画先の要素
 */
function resize(
    renderer: THREE.WebGLRenderer,
    container: HTMLDivElement,
): void {
    const { clientHeight: height, clientWidth: width } = container;

    renderer.setSize(width, height);
    renderer.setPixelRatio(Math.min(Math.max(window.devicePixelRatio, 1), 2));
}
