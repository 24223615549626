/**
 * ある値から別の値へ徐々に変化する振る舞いを扱うクラスです。
 *
 * CSS Transition に相当する単純な変遷を扱います。
 */
export class Transition {
    readonly #internal: Internal;

    /**
     * {@link Transition} インスタンスを初期化します。
     *
     * 直接インスタンス化せず、`TransitionPool` のメソッドをご利用ください。
     *
     * @param start 開始時刻 (`Date.now()` の値)
     * @param duration 継続時間 (ミリ秒)
     * @param action フレーム毎に呼ばれる関数。引数は 0.0-1.0 の値。
     */
    constructor(
        start: number,
        duration: number,
        action: (progress: number) => void,
    ) {
        this.#internal = { action, duration, start };
    }

    /**
     * 1 フレーム処理します。
     *
     * @param now 現在時刻 (`Date.now()` の値)
     * @returns 継続中ならば `true`、完了していれば `false`。
     */
    update(now: number): boolean {
        const { action, duration, start } = this.#internal;
        if (now < start) {
            return true;
        }

        const progress = Math.min(1, (now - start) / duration);
        action(progress);

        return progress < 1;
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

/**
 * {@link Transition} クラスの内部データ
 */
interface Internal {
    /** 変遷の処理 */
    readonly action: (progress: number) => void;
    /** 変遷にかける時間 [ミリ秒] */
    readonly duration: number;
    /** 開始時刻 (`Date.now()`の値) */
    readonly start: number;
}
