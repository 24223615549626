import { useEffect, useState } from "react";
import { WorkHistory } from "../../API";
import { KarteS3Controller } from "../../controller/karte-s3-controller";
import { IMAGE_MAX_NUM } from "../components/pages/node-page/features/karte/constants";

export type KarteImages = {
    karteId: string;
    images: File[];
};

export function useKarteImages(
    workHistories: readonly WorkHistory[] | undefined,
): KarteImages[] {
    const [result, setResult] = useState<KarteImages[]>([]);

    useEffect(() => {
        if (!workHistories) {
            return;
        }

        workHistories.map((workHistory) => {
            const ac = new AbortController();
            const karteS3Controller = new KarteS3Controller(
                workHistory.nodeId,
                workHistory.id,
                IMAGE_MAX_NUM,
            );

            karteS3Controller
                .getKarteImages()
                .then((response) => {
                    if (ac.signal.aborted) {
                        return;
                    }
                    const images: KarteImages = {
                        karteId: workHistory.id,
                        images: response,
                    };
                    setResult((result) => [...result, images]);
                })
                .catch((error) => {
                    if (ac.signal.aborted) {
                        return;
                    }
                    console.error(error);
                });
            return () => {
                ac.abort();
            };
        });
    }, [workHistories]);

    return result;
}
