import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { KartePosition, KarteScreenPosition } from "../three";
import { TransitionPool } from "./transition-pool";

/**
 * カメラとカメラの動きを管理するクラスです。
 *
 * 次のイベントを発火します。
 *
 * - `change` ... ユーザー操作によってカメラの状態が変化したとき
 */
export class Camera extends EventTarget {
    readonly #internal: Internal;

    /**
     * 新しい {@link Camera} インスタンスを初期化します。
     *
     * @param container 描画先の要素
     * @param rootObject 3D モデルのルート オブジェクト
     */
    constructor(container: HTMLDivElement, rootObject: THREE.Object3D) {
        super();

        // 内部データ初期化
        const camera = createThreeCamera(container, rootObject);
        const controller = createController(container, camera);
        this.#internal = {
            camera,
            container,
            controller,
            transitions: new TransitionPool(),
        };

        // イベント購読
        controller.addEventListener("change", () => {
            this.dispatchEvent(new Event("change"));
        });
    }

    /**
     * カメラ インスタンスです。
     *
     * (レンダラの `render` メソッドに渡すために必要...)
     */
    get camera(): THREE.Camera {
        return this.#internal.camera;
    }

    /**
     * 破棄します。
     */
    dispose(): void {
        const { camera, controller } = this.#internal;

        controller.dispose();
        camera.parent?.remove(camera);
    }

    /**
     * カメラのアスペクト比を再計算します。
     *
     * 描画先要素のサイズ変更時に呼んでください。
     */
    resetAspectRatio(): void {
        const { camera, container } = this.#internal;
        const { clientHeight: height, clientWidth: width } = container;
        const aspect = width / height;

        if (aspect === camera.aspect) {
            return;
        }

        camera.aspect = aspect;
        camera.updateProjectionMatrix();
    }

    /**
     * カメラのドラッグ操作の有効・無効を設定します。
     *
     * @param value 設定する値
     */
    setControlEnabled(value: boolean): void {
        const { controller } = this.#internal;
        controller.enabled = value;
    }

    /**
     * カメラの焦点位置を設定します。
     *
     * @param value 設定する値
     */
    setFocusPosition(value: THREE.Vector3, immediate: boolean): void {
        const { controller, transitions } = this.#internal;
        transitions.setVector3({
            duration: immediate ? 0 : 667,
            id: "focusPosition",
            target: controller.target,
            value,
        });
    }

    /**
     * カメラの位置を設定します。
     *
     * @param value 設定する値
     * @param immediate 即座に位置変更するフラグ
     */
    setPosition(value: THREE.Vector3, immediate: boolean): void {
        const { camera, transitions } = this.#internal;
        transitions.setVector3({
            duration: immediate ? 0 : 667,
            id: "cameraPosition",
            target: camera.position,
            value,
        });
    }

    /**
     * カメラの移動処理等を実施します。
     * 毎フレーム呼んでください。
     *
     * @returns 描画更新があれば真
     */
    update(now: number): boolean {
        const { controller, transitions } = this.#internal;
        const transitionsUpdate = transitions.update(now);
        const controllerUpdate = controller.update();

        if (transitionsUpdate || controllerUpdate) {
            this.#dump();
        }

        return transitionsUpdate || controllerUpdate;
    }

    /**
     * 3D空間上の座標をViewer平面の2D座標に投影する。
     * @param childNodes 対象の子オブジェクト
     * @returns 子オブジェクトの3D座標をスクリーン上に投影した2D座標
     */
    project(childNodes: THREE.Object3D[]): NodeScreenPosition[] {
        const { camera, container } = this.#internal;

        const nodeScreenPositions = childNodes.map(
            (childNode: THREE.Object3D) => {
                const screenPosition = childNode.position
                    .clone()
                    .project(camera);
                const projectedNode: NodeScreenPosition = {
                    nodeId: childNode.name,
                    translateX: screenPosition.x * container.clientWidth * 0.5,
                    translateY:
                        -screenPosition.y * container.clientHeight * 0.5,
                };
                return projectedNode;
            },
        );

        return nodeScreenPositions;
    }

    /**
     *
     */
    projectKarte(kartePosition: KartePosition): KarteScreenPosition {
        const { camera, container } = this.#internal;

        const karteScreenPosition = new THREE.Vector3(
            kartePosition.x,
            kartePosition.y,
            kartePosition.z,
        ).project(camera);
        const projectedKarte: KarteScreenPosition = {
            id: kartePosition.id,
            translateX: karteScreenPosition.x * container.clientWidth * 0.5,
            translateY: -karteScreenPosition.y * container.clientHeight * 0.5,
        };
        return projectedKarte;
    }

    //--------------------------------------------------------------------------
    // Private Methods
    //--------------------------------------------------------------------------

    /**
     * カメラの位置と焦点位置を devtools コンソールにダンプします。
     */
    #dump(): void {
        const { camera, controller } = this.#internal;

        // eslint-disable-next-line no-console -- 位置決めのために見たい
        console.debug(
            "Camera: (%o, %o, %o) ⇒ (%o, %o, %o)",
            camera.position.x,
            camera.position.y,
            camera.position.z,
            controller.target.x,
            controller.target.y,
            controller.target.z,
        );
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

/**
 * {@link Camera} クラスの内部データ
 */
interface Internal {
    /** カメラ */
    readonly camera: THREE.PerspectiveCamera;
    /** 描画先の要素 */
    readonly container: HTMLDivElement;
    /** カメラのコントローラー */
    readonly controller: OrbitControls;
    /** 変遷プール */
    readonly transitions: TransitionPool;
}

export interface NodeScreenPosition {
    nodeId: string;
    translateX: number;
    translateY: number;
}

/**
 * 新しい {@link THREE.PerspectiveCamera} インスタンスを作成します。
 *
 * @param container 描画先の要素
 * @param rootObject 3D モデルのルート オブジェクト
 */
function createThreeCamera(
    container: HTMLDivElement,
    rootObject: THREE.Object3D,
): THREE.PerspectiveCamera {
    const camera = new THREE.PerspectiveCamera(
        75,
        container.clientWidth / container.clientHeight,
        0.1,
        10000,
    );
    camera.rotation.reorder("YXZ");
    rootObject.add(camera);

    return camera;
}

/**
 * 新しい {@link OrbitControls} インスタンスを作成します。
 *
 * @param container 描画先の要素
 * @param camera Three.js カメラ オブジェクト
 */
function createController(
    container: HTMLDivElement,
    camera: THREE.Camera,
): OrbitControls {
    const controller = new OrbitControls(camera, container);
    controller.enableDamping = true;
    controller.enableRotate = true;
    controller.enabled = true;
    controller.screenSpacePanning = true;

    return controller;
}
