import * as THREE from "three";
import { AddKarteMode } from "../components/pages/node-page/features/graphic-background/graphic-background-viewer";
import { Camera } from "./internal/camera";
import { debug } from "./internal/debug";
import { KeyingQueue } from "./internal/keying-queue";
import { Pointer } from "./internal/pointer";
import { Quartz } from "./internal/quartz";
import { Renderer } from "./internal/renderer";
import { Resource } from "./internal/resource";
import { World } from "./internal/world";
import { KarteEvent } from "./karte-event";
import { LoadEvent } from "./load-event";
import { ObjectEvent } from "./object-event";
import { RenderEvent } from "./render-event";
import { Theme } from "./theme";

export interface NodeScreenPosition {
    readonly nodeId: string;
    readonly translateX: number;
    readonly translateY: number;
}

export interface KartePosition {
    readonly id: string;
    readonly x: number;
    readonly y: number;
    readonly z: number;
}

export interface KarteScreenPosition {
    readonly id: string;
    readonly translateX: number;
    readonly translateY: number;
}

/**
 * 3D モデルの表示を扱うファサード・クラスです。
 */
export class Three extends EventTarget {
    readonly #internal: Internal;

    /**
     * 新しい {@link Three} インスタンスを初期化します。
     *
     * @param container 描画先の要素
     */
    constructor(container: HTMLDivElement) {
        super();

        // 内部データを初期化
        const world = new World();
        const camera = new Camera(container, world.rootObject);
        const pointer = new Pointer(container);
        const quartz = new Quartz();
        const queue = new KeyingQueue();
        const renderer = new Renderer(container);
        const resizeObserver = new ResizeObserver(this.#onResize.bind(this));
        const resource = new Resource();

        resizeObserver.observe(container);
        this.#internal = {
            camera,
            pointer,
            quartz,
            queue,
            renderer,
            resizeObserver,
            resource,
            world,
        };

        // イベントハンドリング
        camera.addEventListener("change", this.#resumeRendering.bind(this));
        pointer.addEventListener("change", this.#resumeRendering.bind(this));
        pointer.addEventListener("click", this.#forwardPointerEvent.bind(this));
        pointer.addEventListener("enter", this.#forwardPointerEvent.bind(this));
        pointer.addEventListener("leave", this.#forwardPointerEvent.bind(this));
        pointer.addEventListener(
            "setKarte",
            this.#forwardKarteEvent.bind(this),
        );
        quartz.addEventListener("tick", this.#render.bind(this));
    }

    /**
     * 破棄します。
     */
    dispose(): void {
        const {
            camera,
            pointer,
            quartz,
            renderer,
            resizeObserver,
            resource,
            world,
        } = this.#internal;

        camera.dispose();
        pointer.dispose();
        quartz.dispose();
        renderer.dispose();
        resizeObserver.disconnect();
        resource.dispose();
        world.dispose();
    }

    /**
     * カメラの位置を再設定します。
     *
     * このメソッドでは、カメラの向く先は変更されません。
     * カメラの向く先は {@link setObjectNames} メソッドで指定された主要オブジェ
     * クトの中心になります。
     *
     * キーが現在のモデルのキーではなかった場合、モデル読み込み後に設定されます。
     * このとき、カメラの位置をゆっくり動かすのではなく、即座に位置変更します。
     *
     * @param key 対象キー
     * @param x X軸の値
     * @param y Y軸の値
     * @param z Z軸の値
     */
    setCameraPosition(key: string, x: number, y: number, z: number): void {
        debug("REQ setCameraPosition(%o, %o, %o, %o)", key, x, y, z);
        const { camera, quartz, queue } = this.#internal;

        // 現在のキーと異なるキーの位置を変更する場合、一瞬で変更する
        const immediate = key !== queue.currentKey;
        queue.enqueue(key, () => {
            debug("ACT setCameraPosition(%o, %o, %o, %o)", key, x, y, z);
            camera.setPosition(new THREE.Vector3(x, y, z), immediate);
            quartz.resume();
        });
    }

    /**
     * 操作の有効・無効フラグを設定します。
     *
     * ドラッグによる視点移動やホバー・クリック操作に影響します。
     *
     * @param value 設定する値
     */
    setControlEnabled(value: boolean): void {
        const { camera, pointer, quartz } = this.#internal;

        camera.setControlEnabled(value);
        pointer.setControlEnabled(value);

        // 描画を再開する
        quartz.resume();
    }

    /**
     * 注目するオブジェクトを設定します。
     *
     * 注目するオブジェクトは色が変化します。
     *
     * キーが現在のモデルのキーではなかった場合、モデル読み込み後に設定されます。
     *
     * @param key 対象キー
     * @param objectName 注目するオブジェクトの名前
     */
    setFocus(key: string, objectName: string | undefined): void {
        debug("REQ setFocus(%o, %o)", key, objectName);
        const { quartz, queue, world } = this.#internal;

        queue.enqueue(key, () => {
            debug("ACT setFocus(%o, %o)", key, objectName);
            world.setFocus(objectName);
            quartz.resume();
        });
    }

    /**
     * 3D モデルの URL を設定します。
     *
     * 指定モデルの読込を開始します。
     * 読込中、`progress` イベントが発火して読込の進捗を伝えます。読込が完了する
     * と `load` イベントを発火します。
     *
     * もしも指定モデルがキャッシュされていた場合は、`progress` イベントは発生し
     * ません。いきなり `load` イベントを発火します。
     *
     * 読込中に再び {@link setModelUrl} メソッドが呼ばれた場合、それまでの読込は
     * 中止され、新しい読込が始まります。
     *
     * モデル読み込み後に、指定キーのアクション・キューを全て実行します。
     *
     * @param key 対象キー
     * @param url 設定する値
     */
    setModelUrl(key: string, url: string | undefined): void {
        debug("*** setModelUrl(%o, %o)", key, url);
        const { quartz, queue, resource, world } = this.#internal;

        // 新しいモデルを読み込む
        if (url === undefined) {
            world.setScene(undefined);
            queue.activate(key);
            quartz.resume();
        } else {
            resource
                .load(url, this.#onResourceProgress.bind(this))
                .then(this.#onResourceLoad.bind(this, key, url))
                .catch(this.#onResourceError.bind(this));
        }
    }

    /**
     * 主要オブジェクトとクリック可能なオブジェクトを設定します。
     *
     * 新しい主要オブジェクトの位置にカメラの焦点を移動し、新しいクリック可能オ
     * ブジェクトをポインタ管理に伝えます。
     *
     * キーが現在のモデルのキーではなかった場合、モデル読み込み後に設定されます。
     * このとき、カメラの焦点位置をゆっくり動かすのではなく、即座に変更します。
     *
     * @param key 対象キー
     * @param mainObjectName 主要オブジェクトの名前
     * @param clickableObjectNames クリック可能なオブジェクトの名前
     */
    setObjectNames(
        key: string,
        mainObjectName: string,
        clickableObjectNames: Iterable<string>,
    ): void {
        debug(
            "REQ setObjectNames(%o, %o, %o)",
            key,
            mainObjectName,
            clickableObjectNames,
        );
        const { camera, pointer, quartz, queue, world } = this.#internal;

        // 現在のキーと異なるキーの位置を変更する場合、一瞬で変更する
        const immediate = key !== queue.currentKey;
        queue.enqueue(key, () => {
            debug(
                "ACT setObjectNames(%o, %o, %o)",
                key,
                mainObjectName,
                clickableObjectNames,
            );
            const { clickableObjects, mainObject } = world.setObjectNames(
                mainObjectName,
                clickableObjectNames,
            );
            camera.setFocusPosition(mainObject.position, immediate);
            pointer.setClickableObjects(clickableObjects);

            // 子Nodeのオブジェクトがない場合でもメモは設置できるため、設置対象オブジェクトは個別でセットする。
            pointer.setMainObject(mainObject);

            quartz.resume();
        });
    }

    /**
     * 表示テーマを設定します。
     *
     * テーマにより、各種マテリアルの色情報を変化させます。
     *
     * @param theme テーマ
     */
    setTheme(theme: Theme): void {
        const { quartz, world } = this.#internal;

        world.setTheme(theme);

        // 描画を再開する
        quartz.resume();
    }

    /**
     * 警告が発生しているNodeの情報をセットします。
     *
     * 警告の有無により、対象オブジェクトのマテリアルの色を変化させます。
     */
    setWarning(warningNodeNames: string[]): void {
        const { world } = this.#internal;

        world.setWarningNodes(warningNodeNames);
    }

    /**
     * 現在のNodeに紐づくメモの座標をセットします。
     *
     * ここでセットされたメモが3DViewer上で表示されます。
     */
    setKartePositions(kartePositions: KartePosition[] | undefined): void {
        const { world } = this.#internal;

        world.setKartePositions(kartePositions);
    }

    /**
     * メモ設置モードの状態をセットする。
     *
     * メモ設置モードの状態に応じて、オブジェクトのクリックによるNode遷移やオブジェクトのホバーによる色変化などのON/OFFを切り替える。
     */
    setAddKarteMode(addKarteMode: AddKarteMode): void {
        const { pointer } = this.#internal;

        pointer.setAddKarteMode(addKarteMode);
    }

    //--------------------------------------------------------------------------
    // Private Methods
    //--------------------------------------------------------------------------

    /**
     * ポインターイベントを転送します。
     *
     * @param event イベント
     */
    #forwardPointerEvent(event: Event): void {
        const { quartz } = this.#internal;
        const { objectName, type } = event as ObjectEvent;

        // 描画更新を再開する
        quartz.resume();

        this.dispatchEvent(new ObjectEvent(type, { objectName }));
    }

    /**
     * 製品カルテのメモ設置イベントを転送します。
     * @param event イベント
     */
    #forwardKarteEvent(event: Event): void {
        const { quartz, world } = this.#internal;
        const { positionX, positionY, positionZ, type } = event as KarteEvent;

        quartz.resume();

        world.setTemporaryKartePosition({
            id: "",
            x: positionX,
            y: positionY,
            z: positionZ,
        });

        this.dispatchEvent(
            new KarteEvent(type, { positionX, positionY, positionZ }),
        );
    }

    /**
     * 描画先要素のサイズ変更時に行うべき事を処理します。
     *
     * カメラとレンダラーの描画サイズを再計算します。
     */
    #onResize(): void {
        const { camera, renderer } = this.#internal;

        camera.resetAspectRatio();
        renderer.resetRenderingSize();
    }

    /**
     * 3D モデル ファイルの読み込みエラーを処理します。
     *
     * 中止エラーは無視します。それ以外の場合は `error` イベントを発火します。
     *
     * @param error エラー
     */
    #onResourceError(error: unknown): void {
        const message = error instanceof Error ? error.message : String(error);
        if (message === "canceled") {
            return;
        }

        // イベントを発火する
        this.dispatchEvent(new ErrorEvent("error", { error, message }));
    }

    /**
     * 3D モデル ファイルの読み込み完了を処理します。
     *
     * 表示する 3D モデルを入れ替えて、新しい主要オブジェクトの位置にカメラの焦
     * 点を移動し、新しいクリック可能オブジェクトをポインタ管理に伝えます。
     *
     * @param key 対象キー
     * @param url 3D モデル ファイルの URL
     * @param scene 3D モデル
     */
    #onResourceLoad(key: string, url: string, scene: THREE.Group): void {
        debug("*** onResourceLoad(%o, %o)", key, url);
        const { quartz, queue, world } = this.#internal;

        // 読み込んだシーンを設定する
        world.setScene(scene);
        // このシーンのアクション・キューを実行する
        queue.activate(key);
        // 描画更新を再開する
        quartz.resume();

        // イベントを発火する
        this.dispatchEvent(new LoadEvent("load", { scene, url }));
    }

    /**
     * 3D モデル ファイルの読み込み状況更新イベントを処理します。
     *
     * `progress` イベントを発火します。
     *
     * @param progress 進捗状況
     */
    #onResourceProgress(progress: ProgressEvent): void {
        const { lengthComputable, loaded, total } = progress;

        this.dispatchEvent(
            new ProgressEvent("progress", { lengthComputable, loaded, total }),
        );
    }

    /**
     * 描画します。
     *
     * 描画に関わる状態が変化していない場合、描画ループを一時停止します。
     * (消費電力削減のため)
     *
     * 状態変化が誘発される時に `quartz.resume()` してください。
     */
    #render(): void {
        const { camera, pointer, quartz, renderer, world } = this.#internal;
        const now = Date.now();

        // モデルの色変更アニメーション等を処理する
        const worldUpdate = world.update(now);
        // カメラの移動アニメーション等を処理する
        const cameraUpdate = camera.update(now);
        // ポインター状態の更新を処理する
        const pointerUpdate = pointer.update(camera.camera);
        // 3D空間上の座標をViewer平面に投影する
        const nodeScreenPositions = this.#getScreenPositions();
        const karteScreenPositions = this.#getKarteScreenPositions();
        const temporaryKarteScreenPosition =
            this.#getTemporaryKarteScreenPosition();

        this.dispatchEvent(
            new RenderEvent("render", {
                nodeScreenPositions,
                karteScreenPositions,
                temporaryKarteScreenPosition,
            }),
        );

        // カメラの位置が更新していたら、描画順序を更新する
        if (cameraUpdate) {
            world.setRenderOrderWith(camera.camera.position);
        }

        // 描画更新する
        renderer.update(world.rootObject, camera.camera);

        // 状態変化が無ければ描画更新を一時停止する
        if (!cameraUpdate && !pointerUpdate && !worldUpdate) {
            quartz.pause();
        }
    }

    /**
     * 描画が一時停止していた場合は再開します。
     */
    #resumeRendering(): void {
        const { quartz } = this.#internal;

        quartz.resume();
    }

    /**
     * 現在のNodeのオブジェクトの子オブジェクトの2D座標を返す
     *
     * 子Nodeに該当するオブジェクト以外にも、childrenに含まれるオブジェクトすべての2D座標を返す。
     * そのため、これらの座標に基づきIoTデータを表示する際には、React側で子Nodeのもののみを抽出して表示する必要がある。
     * @returns 子オブジェクトの3D座標をスクリーン上に投影した2D座標
     */
    #getScreenPositions(): NodeScreenPosition[] {
        const { camera, world } = this.#internal;

        // 子ノードの配列を取得する
        const childNodes = world.getChildObjects();

        // 各Nodeオブジェクト原点座標を2Dスクリーンへ投影する
        const nodeScreenPositions = camera.project(childNodes);
        return nodeScreenPositions;
    }

    /**
     * 現在のNodeに紐づくメモのスクリーン座標を返します。
     *
     * @returns 現在のNodeに紐づくメモの2次元座標
     */
    #getKarteScreenPositions(): KarteScreenPosition[] {
        const { camera, world } = this.#internal;

        const kartePositions = world.getKartePositions();
        const karteScreenPositions = kartePositions.map((kartePosition) =>
            camera.projectKarte(kartePosition),
        );
        return karteScreenPositions;
    }

    /**
     * 設置中のメモのスクリーン座標を返します。
     *
     * メモ設置位置確認の時に、メモのポインタを一時的に表示するためのもの。
     * @returns 設置中のメモの2次元座標
     */
    #getTemporaryKarteScreenPosition(): KarteScreenPosition {
        const { camera, world } = this.#internal;

        const kartePosition = world.getTemporaryKartePosition();
        const karteScreenPosition = camera.projectKarte(kartePosition);
        return karteScreenPosition;
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

/**
 * {@link Three} クラスの内部データ
 */
interface Internal {
    /** カメラ管理 */
    readonly camera: Camera;
    /** ポインタ管理 */
    readonly pointer: Pointer;
    /** 振動子 */
    readonly quartz: Quartz;
    /** アクション・キュー */
    readonly queue: KeyingQueue;
    /** 描画管理 */
    readonly renderer: Renderer;
    /** サイズ変更管理 */
    readonly resizeObserver: ResizeObserver;
    /** リソース管理 */
    readonly resource: Resource;
    /** モデル管理 */
    readonly world: World;
}
