/**
 * {@link KarteEvent} の初期化パラメーター
 */
export interface KarteEventInit extends EventInit {
    /**
     * メモを設置した位置の座標
     */
    readonly positionX: number;
    readonly positionY: number;
    readonly positionZ: number;
}

/**
 * モデルの読込が完了したことを伝えるイベント
 */
export class KarteEvent extends Event {
    readonly positionX: number;
    readonly positionY: number;
    readonly positionZ: number;

    /**
     * 新しい {@link KarteEvent} インスタンスを初期化します。
     *
     * @param type イベント種別
     * @param eventInitDict 初期化パラメーター
     */
    constructor(type: string, eventInitDict: KarteEventInit) {
        const { positionX, positionY, positionZ, ...restInit } = eventInitDict;
        super(type, restInit);
        this.positionX = positionX;
        this.positionY = positionY;
        this.positionZ = positionZ;
    }
}
