import * as THREE from "three";
import { Transition } from "./transition";

export namespace TransitionPool {
    /**
     * 変遷の初期化パラメーター
     */
    export interface CommonParameters {
        /**
         * 変遷にかける時間 (ミリ秒)
         *
         * @default 150
         */
        readonly duration?: number;
        /**
         * 変遷の識別子。
         *
         * 同じ識別子の変遷が既にある場合、それを中止してから新しい変遷を始めます。
         *
         * @default a new UUID
         */
        readonly id?: string;
        /**
         * 開始時刻 (`Date.now()`の値)
         *
         * @default Date.now()
         */
        readonly start?: number;
    }

    /**
     * 変遷の初期化パラメーター
     */
    export interface Parameters extends CommonParameters {
        /**
         * 変遷の振る舞い
         * @param progress 現在の進捗。`0` から `1` の間の値。
         */
        readonly action: (progress: number) => void;
    }

    /**
     * 変遷の初期化パラメーター
     */
    export interface ColorParameters extends CommonParameters {
        /**
         * 変更対象の色オブジェクト
         */
        readonly target: THREE.Color;
        /**
         * 設定する値
         */
        readonly value: string | number | THREE.Color;
    }

    /**
     * 変遷の初期化パラメーター
     */
    export interface MaterialOpacityParameters extends CommonParameters {
        /**
         * 変更対象の Material オブジェクト
         */
        readonly target: THREE.MeshStandardMaterial;
        /**
         * 設定する値
         */
        readonly value: number;
    }

    /**
     * 変遷の初期化パラメーター
     */
    export interface Vector3Parameters extends CommonParameters {
        /**
         * 変更対象の Vector3 オブジェクト
         */
        readonly target: THREE.Vector3;
        /**
         * 設定する値
         */
        readonly value: THREE.Vector3;
    }
}

/**
 * 値を徐々に変化させる変遷を管理するクラスです。
 *
 * CSS Transition 相当のことをします。
 *
 * 各変遷オブジェクトには ID をつけることができます。
 * 変遷を新しく作るとき、同じ ID の変遷が既存だった場合は上書きします。
 * (同じプロパティを変更する変遷を重複させないために利用できます)
 *
 * 各変遷は `duration` で指定した時間が経過すると自動的に破棄されます。
 */
export class TransitionPool {
    readonly #internal: Internal = {
        transitions: new Map(),
    };

    /**
     * 色を変更する変遷を作成します。
     *
     * @param parameters パラメーター
     */
    setColor(parameters: TransitionPool.ColorParameters): void {
        const { start, target, value, duration, id } = parameters;
        const initial = target.clone();
        const final = new THREE.Color(value);

        if (final.equals(initial)) {
            if (id !== undefined) {
                this.#internal.transitions.delete(id);
            }
            return;
        }

        this.#createTransition({
            action(progress) {
                target.lerpColors(initial, final, progress);
            },
            duration,
            id,
            start,
        });
    }

    /**
     * マテリアルの不透明度を変更する変遷を作成します。
     *
     * @param parameters パラメーター
     */
    setMaterialOpacity(
        parameters: TransitionPool.MaterialOpacityParameters,
    ): void {
        const { duration, id, start, target, value: final } = parameters;
        const initial = target.opacity;

        if (final === initial) {
            if (id !== undefined) {
                this.#internal.transitions.delete(id);
            }
            return;
        }

        this.#createTransition({
            action(progress) {
                target.opacity = initial + (final - initial) * progress;
            },
            duration,
            id,
            start,
        });
    }

    /**
     * 3次元ベクトルを変更する変遷を作成します。
     *
     * @param parameters パラメーター
     */
    setVector3(parameters: TransitionPool.Vector3Parameters): void {
        const { duration, id, start, target, value } = parameters;
        const initial = target.clone();
        const final = value.clone();

        if (final.equals(initial)) {
            if (id !== undefined) {
                this.#internal.transitions.delete(id);
            }
            return;
        }

        this.#createTransition({
            action(progress) {
                target.lerpVectors(initial, final, progress);
            },
            duration,
            id,
            start,
        });
    }

    /**
     * このプールが管理する変遷を 1 フレーム進めます。
     *
     * @param now 現在時刻 (`Date.now()`の値)
     * @returns 変遷が存在していたならば真
     */
    update(now: number): boolean {
        const { transitions } = this.#internal;

        for (const [id, transition] of transitions) {
            if (!transition.update(now)) {
                transitions.delete(id);
            }
        }

        return transitions.size > 0;
    }

    //--------------------------------------------------------------------------
    // Private Methods
    //--------------------------------------------------------------------------

    /**
     * 新しい変遷を作成します。
     *
     * @param parameters パラメーター
     */
    #createTransition(parameters: TransitionPool.Parameters): void {
        const { transitions } = this.#internal;
        const {
            action,
            duration = DefaultDuration,
            id = crypto.randomUUID(),
            start = Date.now(),
        } = parameters;

        transitions.set(id, new Transition(start, duration, action));
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

/**
 * {@link TransitionPool} の内部データ
 */
interface Internal {
    /** 現在変遷しているもの */
    readonly transitions: Map<string, Transition>;
}

/** 変遷にかける時間の初期値 */
const DefaultDuration = 150;
