import { useEffect, useState } from "react";
import { Node, NodeTable } from "../../model/database";
import { Result } from "./result";
import { useDatabase, useUpdateRevision } from "./use-database";

export function useAggregateWarningNodes(
    nodes: readonly Node[],
): Result<Node[]> {
    const nodeTable = useDatabase().nodes;
    const nodeRevision = useUpdateRevision(nodeTable);
    const [result, setResult] = useState<Result<Node[]>>({});

    useEffect(() => {
        const ac = new AbortController();
        getWarningNodes(nodes, nodeTable)
            .then((nodes: Node[]) => {
                if (!ac.signal.aborted) {
                    setResult({
                        data: nodes,
                    });
                }
            })
            .catch((error: unknown) => {
                if (!ac.signal.aborted) {
                    setResult({ error });
                }
            });

        return () => {
            ac.abort();
        };
    }, [nodes, nodeRevision, nodeTable]);
    return result;
}

async function getWarningNodes(
    nodes: readonly Node[],
    nodeTable: NodeTable,
): Promise<Node[]> {
    const warningNodes: Node[] = [];

    await Promise.all(
        nodes.map(async (node) => {
            // 対象Nodeに警告が出ているかチェック
            let aggregatedWarning = node.isWarning ?? false;

            // 対象Nodeの子Node
            const childNodes: readonly Node[] = await nodeTable.findGreedily({
                index: "listNodesByParentId",
                parentId: node.id,
            });

            // 対象Nodeの子Nodeに警告が出ているかチェック
            aggregatedWarning ||= childNodes.some((node) => node.isWarning);

            // 対象Nodeの子Nodeのさらに子孫Nodeに警告が出ているかチェック
            await Promise.all(
                childNodes.map(async (child) => {
                    const warning = await getAggregatedChildNodesWarning(
                        child.id,
                        nodeTable,
                    );
                    aggregatedWarning ||= warning;
                }),
            );

            if (aggregatedWarning) {
                warningNodes.push(node);
            }
        }),
    );

    return warningNodes;
}

async function getAggregatedChildNodesWarning(
    id: string,
    nodeTable: NodeTable,
): Promise<boolean> {
    const childNodes: readonly Node[] = await nodeTable.findGreedily({
        index: "listNodesByParentId",
        parentId: id,
    });

    let aggregatedWarning = childNodes.some((node) => node.isWarning);

    await Promise.all(
        childNodes.map(async (child) => {
            const warning = await getAggregatedChildNodesWarning(
                child.id,
                nodeTable,
            );
            aggregatedWarning ||= warning;
        }),
    );

    return aggregatedWarning;
}
