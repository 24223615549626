import * as THREE from "three";
import { AddKarteMode } from "../../components/pages/node-page/features/graphic-background/graphic-background-viewer";
import { KarteEvent } from "../karte-event";
import { ObjectEvent } from "../object-event";

/**
 * マウス・タッチの検出とオブジェクトとの当たり判定を管理するクラスです。
 *
 * 以下のイベントを送出します。
 *
 * - `click` ({@link ObjectEvent}) ... クリック可能なオブジェクトをクリックした
 * - `enter` ({@link ObjectEvent}) ... クリック可能なオブジェクトの内にカーソルが移動した
 * - `leave` ({@link ObjectEvent}) ... クリック可能なオブジェクトの外にカーソルが移動した
 */
export class Pointer extends EventTarget {
    readonly #internal: Internal;

    /**
     * 新しい {@link Pointer} インスタンスを初期化します。
     *
     * @param container 描画先の要素
     */
    constructor(container: HTMLDivElement) {
        super();

        // 内部データ初期化
        const handlePointerDown = this.#onPointerDown.bind(this);
        const handlePointerLeave = this.#onPointerLeave.bind(this);
        const handlePointerMove = this.#onPointerMove.bind(this);
        const handlePointerUp = this.#onPointerUp.bind(this);
        this.#internal = {
            container,
            handlePointerDown,
            handlePointerLeave,
            handlePointerMove,
            handlePointerUp,
            raycaster: new THREE.Raycaster(),
            state: {
                clickableObjects: [],
                mainObject: undefined,
                currentObject: undefined,
                currentPosition: new THREE.Vector2(),
                downPosition: new THREE.Vector2(),
                enabled: true,
                inside: false,
                lastCameraPosition: new THREE.Vector3(),
                lastCameraQuaternion: new THREE.Quaternion(),
                lastCameraScale: new THREE.Vector3(),
                lastPosition: new THREE.Vector2(),
                addKarteMode: AddKarteMode.None,
            },
        };

        // イベント購読
        const listenerOptions: AddEventListenerOptions = { passive: true };
        container.addEventListener(
            "pointerdown",
            handlePointerDown,
            listenerOptions,
        );
        container.addEventListener(
            "pointerleave",
            handlePointerLeave,
            listenerOptions,
        );
        container.addEventListener(
            "pointermove",
            handlePointerMove,
            listenerOptions,
        );
        container.addEventListener(
            "pointerup",
            handlePointerUp,
            listenerOptions,
        );
    }

    /**
     * 破棄します。
     */
    dispose(): void {
        const {
            container,
            handlePointerDown,
            handlePointerLeave,
            handlePointerMove,
            handlePointerUp,
        } = this.#internal;

        container.removeEventListener("pointerdown", handlePointerDown);
        container.removeEventListener("pointerleave", handlePointerLeave);
        container.removeEventListener("pointermove", handlePointerMove);
        container.removeEventListener("pointerup", handlePointerUp);
    }

    /**
     * クリック可能なオブジェクトを設定します。
     *
     * ここで設定したオブジェクトとのみ、当たり判定を計算します。
     *
     * 現在ホバーしているオブジェクトがクリック可能ではなくなった場合、`leave`イ
     * ベントが発火します。
     *
     * @param clickableObjects クリック可能なオブジェクト
     */
    setClickableObjects(clickableObjects: Iterable<THREE.Object3D>): void {
        const { state } = this.#internal;

        state.clickableObjects = [...clickableObjects];

        // 現在のオブジェクトがクリック可能でなくなった場合、leave する
        if (
            state.currentObject !== undefined &&
            !state.clickableObjects.includes(state.currentObject)
        ) {
            const oldObject = state.currentObject;
            state.currentObject = undefined;

            this.dispatchEvent(
                new ObjectEvent("leave", { objectName: oldObject.name }),
            );
        }
    }

    /**
     * ポインター関連イベントの有効・無効を設定します。
     *
     * 無効化する際、現在ホバーしているオブジェクトが存在する場合は`leave`イベン
     * トが発火します。
     *
     * @param value 設定する値
     */
    setControlEnabled(value: boolean): void {
        const { state } = this.#internal;

        state.enabled = value;
        if (!value && state.currentObject !== undefined) {
            this.dispatchEvent(
                new ObjectEvent("leave", {
                    objectName: state.currentObject.name,
                }),
            );
            state.currentObject = undefined;
        }
    }

    /**
     * カーソル位置にあるオブジェクトを探索します。
     *
     * ホバーされるオブジェクトが変化した際に`enter`/`leave`イベントを発火します。
     *
     * @param camera 視点となるカメラ
     * @returns 描画更新があれば真
     */
    update(camera: THREE.Camera): boolean {
        const { raycaster, state } = this.#internal;

        // 無効状態なら何もしない
        if (!state.enabled || !state.inside) {
            return false;
        }

        // カメラ状態・カーソル状態のいずれも変化していなければ終了
        if (
            camera.position.equals(state.lastCameraPosition) &&
            camera.quaternion.equals(state.lastCameraQuaternion) &&
            camera.scale.equals(state.lastCameraScale) &&
            state.currentPosition.equals(state.lastPosition)
        ) {
            return false;
        }

        // カーソル位置にあるオブジェクトを見つける
        raycaster.setFromCamera(state.currentPosition, camera);
        const newObject = getAncestorIn(
            raycaster.intersectObjects(state.clickableObjects)[0]?.object,
            state.clickableObjects,
        );
        const oldObject = state.currentObject;

        // enter/leave イベントを (後で) 発火する
        if (newObject !== oldObject) {
            if (oldObject !== undefined) {
                queueMicrotask(() => {
                    const objectName = oldObject.name;
                    this.dispatchEvent(
                        new ObjectEvent("leave", { objectName }),
                    );
                });
            }
            if (
                newObject !== undefined &&
                state.addKarteMode === AddKarteMode.None
            ) {
                queueMicrotask(() => {
                    const objectName = newObject.name;
                    this.dispatchEvent(
                        new ObjectEvent("enter", { objectName }),
                    );
                });
            }
        }

        // 状態更新
        state.currentObject = newObject;
        state.lastCameraPosition.copy(camera.position);
        state.lastCameraQuaternion.copy(camera.quaternion);
        state.lastCameraScale.copy(camera.scale);
        state.lastPosition.copy(state.currentPosition);

        // 完了
        return newObject !== oldObject;
    }

    /**
     * 現在の主要オブジェクトをセットする。
     *
     * 3D空間でのメモ設置において、クリック可能なオブジェクトを判別するために使用する。
     * ※clickableObjectは、子Nodeのオブジェクトが内包されているものしかクリックできず再利用不可のため、別途用意している。
     */
    setMainObject(mainObject: THREE.Object3D): void {
        const { state } = this.#internal;

        state.mainObject = mainObject;
    }

    /**
     * メモ設置モードの状態をセットする。
     *
     * メモ設置モードの状態に応じて、オブジェクトのクリック時に座標情報を更新・発行するかどうかを制御する。
     */
    setAddKarteMode(addKarteMode: AddKarteMode): void {
        const { state } = this.#internal;

        state.addKarteMode = addKarteMode;
    }

    //--------------------------------------------------------------------------
    // Private Methods
    //--------------------------------------------------------------------------

    /**
     * マウスボタン押下を処理します。
     *
     * クリックとドラッグを区別したいので、押下時の座標を保持しておきます。
     *
     * @param event ポインターイベント
     */
    #onPointerDown(event: PointerEvent): void {
        const { container, state } = this.#internal;

        if (!event.isPrimary) {
            return;
        }

        state.downPosition.set(
            (event.clientX / container.clientWidth) * 2 - 1,
            1 - (event.clientY / container.clientHeight) * 2,
        );
    }

    /**
     * マウスカーソルが描画領域を出たときの処理を実施します。
     *
     * ホバーしているオブジェクトが存在した場合、`leave`イベントを発火します。
     *
     * @param event ポインターイベント
     */
    #onPointerLeave(event: PointerEvent): void {
        const { state } = this.#internal;

        if (!event.isPrimary) {
            return;
        }
        if (state.currentObject === undefined) {
            return;
        }
        const oldObject = state.currentObject;

        // 状態更新
        state.currentObject = undefined;
        state.inside = false;

        // leave イベントを発火する
        if (oldObject !== undefined) {
            this.dispatchEvent(
                new ObjectEvent("leave", { objectName: oldObject.name }),
            );
        }
    }

    /**
     * マウスカーソルの移動を処理します。
     *
     * @param event ポインターイベント
     */
    #onPointerMove(event: PointerEvent): void {
        const { container, state } = this.#internal;

        if (!event.isPrimary) {
            return;
        }

        state.currentPosition.set(
            (event.clientX / container.clientWidth) * 2 - 1,
            1 - (event.clientY / container.clientHeight) * 2,
        );
        state.inside = true;

        this.dispatchEvent(new Event("change"));
    }

    /**
     * マウスボタンを離したイベントを処理します。
     *
     * マウスカーソルの下にクリック可能なオブジェクトが存在し、ドラッグしていた
     * のでなければ、`click` イベントを発火します。
     *
     * また、製品カルテのメモの設置位置を通知するため、`setkarte` イベントも発火します。
     *
     * @param event ポインターイベント
     */
    #onPointerUp(event: PointerEvent): void {
        const { container, state, raycaster } = this.#internal;

        if (!event.isPrimary) {
            return;
        }

        if (
            state.mainObject &&
            state.addKarteMode === AddKarteMode.SetPosition
        ) {
            const kartePosition = raycaster.intersectObjects(
                state.mainObject.children,
            )[0]?.point;

            if (kartePosition) {
                this.dispatchEvent(
                    new KarteEvent("setKarte", {
                        positionX: kartePosition.x,
                        positionY: kartePosition.y,
                        positionZ: kartePosition.z,
                    }),
                );
            }
        }

        const threshold = new THREE.Vector2(
            2 * (4 / container.clientWidth),
            2 * (4 / container.clientHeight),
        ).lengthSq();

        if (
            state.enabled &&
            state.currentObject !== undefined &&
            state.currentPosition.distanceToSquared(state.downPosition) <
                threshold &&
            state.addKarteMode === AddKarteMode.None
        ) {
            this.dispatchEvent(
                new ObjectEvent("click", {
                    objectName: state.currentObject.name,
                }),
            );
        }
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

/**
 * {@link Camera} クラスの内部データ
 */
interface Internal {
    /** 描画先・イベント購読先の要素 */
    readonly container: HTMLDivElement;
    /** マウスボタン押下のイベント リスナ */
    readonly handlePointerDown: (event: PointerEvent) => void;
    /** マウス カーソル移動のイベント リスナ */
    readonly handlePointerLeave: (event: PointerEvent) => void;
    /** マウス カーソル移動のイベント リスナ */
    readonly handlePointerMove: (event: PointerEvent) => void;
    /** マウスボタン離すのイベント リスナ */
    readonly handlePointerUp: (event: PointerEvent) => void;
    /** レイキャスター */
    readonly raycaster: THREE.Raycaster;

    /** 内部状態 */
    readonly state: {
        /** クリック可能なオブジェクトのリスト */
        clickableObjects: THREE.Object3D[];
        /** 現在のNodeのオブジェクト */
        mainObject: THREE.Object3D | undefined;
        /** 現在のカーソル位置にあるオブジェクト */
        currentObject: THREE.Object3D | undefined;
        /** 現在のカーソル位置 */
        readonly currentPosition: THREE.Vector2;
        /** ボタン押下の位置 (クリック・ドラッグ判別用) */
        readonly downPosition: THREE.Vector2;
        /** 有効フラグ */
        enabled: boolean;
        /** 描画先の要素内にいるフラグ */
        inside: boolean;
        /** 最後の判定時のカメラの位置 */
        readonly lastCameraPosition: THREE.Vector3;
        /** 最後の判定時のカメラの回転 */
        readonly lastCameraQuaternion: THREE.Quaternion;
        /** 最後の判定時のカメラのズーム */
        readonly lastCameraScale: THREE.Vector3;
        /** 最後の判定時のカーソル位置 */
        readonly lastPosition: THREE.Vector2;
        /** メモ設置モードの状態 */
        addKarteMode: AddKarteMode;
    };
}

/**
 * 指定したメッシュの祖先を調べ、クリック可能なオブジェクトがあればそれを返しま
 * す。
 *
 * @param leafObject 末端のメッシュ
 * @param clickableObjects クリック可能オブジェクトのリスト
 * @returns メッシュが属するクリック可能オブジェクト
 */
function getAncestorIn(
    leafObject: THREE.Object3D,
    clickableObjects: THREE.Object3D[],
): THREE.Object3D | undefined {
    let object: THREE.Object3D | null = leafObject;

    while (object != null && !clickableObjects.includes(object)) {
        object = object.parent;
    }

    return object ?? undefined;
}
