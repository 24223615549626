import * as THREE from "three";
import { DefaultTheme, Theme } from "../theme";
import { KartePosition } from "../three";
import { TransitionPool } from "./transition-pool";

/**
 * 名前付きオブジェクトのセット
 */
export interface NamedObjectSet {
    /** 主要オブジェクト */
    mainObject: THREE.Object3D;
    /** クリック可能なオブジェクト */
    clickableObjects: readonly THREE.Object3D[];
}

/**
 * 画面に表示するモデルを管理するクラスです。
 *
 * 名前のついた Mesh/Group を認識して、名前のついた Mesh/Group 毎に個別の
 * Material を割り当てます。注目状態などによって各 Material の色を変化させること
 * ができます。
 *
 * - {@link setFocus} ......... 注目オブジェクトを設定する。
 * - {@link setObjectNames} ... 主要オブジェクトとクリック可能なオブジェクトを
 *                              設定する。Material 再構築が発生します。
 * - {@link setScene} ......... 3D モデルを設定する。Material 再構築が発生します。
 * - {@link setTheme} ......... 各マテリアルの色を設定する。
 */
export class World {
    readonly #internal: Internal = {
        sceneRoot: new THREE.Scene()
            .add(new THREE.AmbientLight(0xffffff, 0.5))
            .add(new THREE.DirectionalLight(0xffffff, 0.5)),
        state: {
            cameraLocation: new THREE.Vector3(),
            clickableObjectNames: new Set(),
            focusedObjectName: undefined,
            mainObjectName: undefined,
            objectMap: new Map(),
            scene: undefined,
            theme: DefaultTheme,
            warningNodeNames: undefined,
            kartePositions: undefined,
            temporaryKartePosition: { id: "", x: 0, y: 0, z: 0 },
        },
        transitions: new TransitionPool(),
    };

    /**
     * 3D 空間のルート オブジェクトです。
     *
     * (レンダラの `render` メソッドに渡すために必要...)
     */
    get rootObject(): THREE.Object3D {
        const { sceneRoot } = this.#internal;
        return sceneRoot;
    }

    /**
     * 破棄します。
     */
    dispose(): void {
        const { sceneRoot, state } = this.#internal;

        if (state.scene !== undefined) {
            sceneRoot.remove(state.scene);
            disposeScene(state.scene);
        }
    }

    /**
     * 注目するオブジェクトを設定します。
     *
     * 注目するオブジェクトは注目色に変化します。
     * それまで注目していたオブジェクトの色は元に戻ります。
     *
     * @param objectName 注目するオブジェクトの名前
     */
    setFocus(objectName: string | undefined): void {
        const { state, transitions } = this.#internal;
        if (objectName === state.focusedObjectName) {
            return;
        }

        const oldObjectName = state.focusedObjectName;
        const oldFocus = state.objectMap.get(oldObjectName);
        const newFocus = state.objectMap.get(objectName);
        state.focusedObjectName = objectName;

        if (oldObjectName !== undefined) {
            const isWarning = state.warningNodeNames?.includes(oldObjectName);
            setColor(
                transitions,
                oldFocus?.material,
                calcMaterialColor({
                    theme: state.theme,
                    focusedObjectName: state.mainObjectName,
                    objectName,
                    inMainObject: oldFocus?.inMainObject,
                    isWarning,
                }),
            );
        }
        if (objectName !== undefined) {
            const isWarning = state.warningNodeNames?.includes(objectName);

            // eslint-disable-next-line no-console -- 位置決めのために見たい
            console.debug(
                "Focus: %s %o",
                newFocus?.object.name,
                newFocus?.object.position,
            );
            setColor(
                transitions,
                newFocus?.material,
                calcMaterialColor({
                    theme: state.theme,
                    focusedObjectName: objectName,
                    objectName,
                    inMainObject: newFocus?.inMainObject,
                    isWarning,
                }),
            );
        }
    }

    /**
     * モデル内にあるオブジェクトの名前を設定します。
     *
     * ここで指定した名前は、3D モデル内のグループやメッシュの名前と比較され、
     * 該当するグループやメッシュを識別するのに使います。
     *
     * `mainObjectName` と同じ名前のオブジェクトより祖先側のオブジェクト群は透明
     * 化します。
     *
     * `clickableObjectNames` と同じ名前のオブジェクトは注目可能です。
     * {@link setFocus} メソッドで注目すると注目色に変わります。
     *
     * @param mainObjectName 主要オブジェクトの名前
     * @param clickableObjectNames クリック可能なオブジェクトの名前リスト
     * @returns 主要オブジェクトとクリック可能なオブジェクト
     */
    setObjectNames(
        mainObjectName: string,
        clickableObjectNames: Iterable<string>,
    ): NamedObjectSet {
        const { state } = this.#internal;

        state.clickableObjectNames = new Set(clickableObjectNames);
        state.mainObjectName = mainObjectName;
        this.#resetObjectMap();

        return this.#getNamedObjects();
    }

    /**
     * カメラ位置を指定して、描画順序を更新します。
     *
     * @param cameraLocation カメラ位置
     */
    setRenderOrderWith(cameraLocation: THREE.Vector3): void {
        const { state } = this.#internal;

        if (cameraLocation.equals(state.cameraLocation)) {
            return;
        }

        state.cameraLocation.copy(cameraLocation);
        this.#resetRenderOrder();
    }

    /**
     * 表示するシーンを設定します。
     *
     * 現在表示中のシーンは破棄されます。
     *
     * @param scene 新しいシーン
     */
    setScene(scene: THREE.Group | undefined): void {
        const { sceneRoot, state } = this.#internal;

        if (scene === state.scene) {
            return;
        }

        if (state.scene !== undefined) {
            sceneRoot.remove(state.scene);
            disposeScene(state.scene);
        }
        if (scene !== undefined) {
            sceneRoot.add(scene);
        }

        state.scene = scene;
        this.#resetObjectMap();
        this.#resetRenderOrder();
    }

    /**
     * テーマを設定します。
     *
     * 各種マテリアルの色を更新します。
     *
     * @param theme 新しいテーマ
     */
    setTheme(theme: Theme): void {
        const { state } = this.#internal;

        state.theme = theme;
        this.#resetMaterialColors();
    }

    /**
     * 色変更のアニメーション処理等を実施します。
     * 毎フレーム呼んでください。
     *
     * @returns 内容が更新された場合は真
     */
    update(now: number): boolean {
        const { transitions } = this.#internal;

        return transitions.update(now);
    }

    /**
     * 現在のNodeのオブジェクトの子オブジェクトを返します。
     * ※該当する子Nodeの有無に関わらず、全ての子オブジェクトを返します。
     * @returns 現在のNodeのオブジェクトの全ての子オブジェクト
     */
    getChildObjects(): THREE.Object3D[] {
        const { state } = this.#internal;

        const childNodes = state.objectMap.get(state.mainObjectName)?.object
            .children;

        if (!childNodes) {
            return [];
        }

        return childNodes;
    }

    /**
     *
     */
    setWarningNodes(warningNodeNames: string[]): void {
        const { state } = this.#internal;

        state.warningNodeNames = warningNodeNames;
        this.#resetMaterialColors();
    }

    /**
     * 現在のNodeに紐づくメモの座標をセットします。
     *
     * ここでセットされたメモが、3DViewer上に表示されます。
     */
    setKartePositions(kartePositions: KartePosition[] | undefined): void {
        const { state } = this.#internal;

        state.kartePositions = kartePositions;
    }

    /**
     *
     */
    setTemporaryKartePosition(kartePosition: KartePosition | undefined): void {
        if (!kartePosition) {
            return;
        }

        const { state } = this.#internal;

        state.temporaryKartePosition = kartePosition;
    }

    /**
     * 現在のNodeに紐づくメモのメモの3次元座標を取得します。
     */
    getKartePositions(): KartePosition[] {
        const { state } = this.#internal;

        const kartePositions = state.kartePositions;

        if (!kartePositions) {
            return [];
        }

        return kartePositions;
    }

    /**
     * 設置中のメモの3次元座標を取得します。
     */
    getTemporaryKartePosition(): KartePosition {
        const { state } = this.#internal;

        const kartePosition = state.temporaryKartePosition;

        return kartePosition;
    }

    //--------------------------------------------------------------------------
    // Private Methods
    //--------------------------------------------------------------------------

    /**
     * 主要オブジェクトとクリック可能なオブジェクトを取得します。
     *
     * @returns 主要オブジェクトとクリック可能なオブジェクト
     */
    #getNamedObjects(): NamedObjectSet {
        const { sceneRoot, state } = this.#internal;

        return {
            // クリック可能なオブジェクト
            clickableObjects: Array.from(
                state.clickableObjectNames,
                (objectName) => state.objectMap.get(objectName)?.object,
            ).filter(
                (object): object is THREE.Object3D =>
                    object instanceof THREE.Object3D,
            ),

            // 主要オブジェクト
            mainObject:
                state.objectMap.get(state.mainObjectName)?.object ?? sceneRoot,
        };
    }

    /**
     * マテリアルの色を再設定します。
     */
    #resetMaterialColors(): void {
        const { state, transitions } = this.#internal;

        for (const [
            objectName,
            { material, inMainObject },
        ] of state.objectMap) {
            const isWarning = objectName
                ? state.warningNodeNames?.includes(objectName)
                : false;
            setColor(
                transitions,
                material,
                calcMaterialColor({
                    theme: state.theme,
                    focusedObjectName: state.focusedObjectName,
                    objectName,
                    inMainObject,
                    isWarning,
                }),
            );
        }
    }

    /**
     * マテリアル マップを再構築します。
     *
     * シーンを走査し、全 Mesh のマテリアルを入れ替えます。
     * このとき、以下のように Material を分けて作ります。
     *
     * A. `state.mainObjectName` で指定した名前のグループに属すが、
     *    `state.clickableObjectNames` で指定した名前のグループに属さない Mesh
     * B. `state.clickableObjectNames` で指定した名前のグループに属する Mesh
     *    (名前ごとに)
     * C. どちらの名前付きグループにも属さない Mesh
     * D. `state.mainObjectName` で指定した名前のグループに属さないが、
     *    `state.clickableObjectNames` で指定した名前のグループに属する Mesh
     *
     * このうち、A と B は `theme.mainColor` で塗られます。
     * C と D は `theme.trivialColor` で塗られます。
     * そして、B, D はホバー時に注目色に変化することができます。
     */
    #resetObjectMap(): void {
        const { state } = this.#internal;
        const {
            clickableObjectNames,
            focusedObjectName,
            mainObjectName,
            scene,
            theme,
        } = state;
        const objectMap = new Map<string | undefined, ObjectInfo>();
        state.objectMap = objectMap;

        if (scene === undefined) {
            return;
        }

        // 主要オブジェクトとクリック可能オブジェクトを検索してマップを作る
        (function walk(
            object: THREE.Object3D,
            parentObjectName: string | undefined,
            parentInMainObject: boolean | undefined,
        ): void {
            // 主要オブジェクトかクリック可能オブジェクトの名前を持っていれば
            // 名前を更新する。どちらでもなければ親の名前のまま
            const objectName = calcCognitiveObjectName(
                mainObjectName,
                clickableObjectNames,
                parentObjectName,
                object.name,
            );

            // このMeshが主要オブジェクトに含まれる場合はmainColorに、含まれない場合はtrivialColorとする。
            const inMainObject =
                parentInMainObject || objectName === mainObjectName;

            const isWarning = objectName
                ? state.warningNodeNames?.includes(objectName)
                : false;

            // 未発見オブジェクトであれば Map に登録する
            let info = objectMap.get(objectName);
            if (info === undefined) {
                info = { inMainObject, material: undefined, object };
                objectMap.set(objectName, info);
            }

            // マテリアルを入れ替える
            if (object instanceof THREE.Mesh) {
                info.material ??= createMaterial(
                    calcMaterialColor({
                        focusedObjectName,
                        inMainObject,
                        objectName,
                        theme,
                        isWarning,
                    }),
                );

                disposeMaterial(object.material);
                object.material = info.material;
            }

            // 子供も処理する
            for (const child of object.children) {
                walk(child, objectName, inMainObject);
            }
        })(scene, undefined, !mainObjectName);
    }

    /**
     * 各メッシュの描画順を再計算します。
     *
     * カメラ位置から遠いメッシュから順に描画されるようにします。
     */
    #resetRenderOrder(): void {
        const { state } = this.#internal;
        const { cameraLocation: v0, scene } = state;
        const bounds = new THREE.Box3();

        scene?.traverse((mesh) => {
            if (!(mesh instanceof THREE.Mesh)) {
                return;
            }

            // メッシュの外接矩形を計算する
            const geometry = mesh.geometry;
            if (geometry.boundingBox === null) {
                geometry.computeBoundingBox();
            }
            bounds.copy(geometry.boundingBox).applyMatrix4(mesh.matrixWorld);

            // カメラ位置と外接矩形との距離が大きい順に並び替える
            mesh.renderOrder = 1.0 / bounds.distanceToPoint(v0);
        });
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

/**
 * {@link World} クラスの内部データ
 */
interface Internal {
    /** ルート シーン */
    readonly sceneRoot: THREE.Scene;
    /** 状態 */
    readonly state: {
        /** カメラの位置 */
        cameraLocation: THREE.Vector3;
        /** クリック可能なオブジェクトの名前リスト */
        clickableObjectNames: ReadonlySet<string>;
        /** 現在の表示モード */
        focusedObjectName: string | undefined;
        /** 主要オブジェクトの名前 */
        mainObjectName: string | undefined;
        /** オブジェクト名に対応するメッシュorグループのマップ */
        objectMap: ReadonlyMap<string | undefined, ObjectInfo>;
        /** 表示中モデルのシーン */
        scene: THREE.Group | undefined;
        /** 色情報など */
        theme: Theme;
        /** 現在警告が出ているNodeの名前 */
        warningNodeNames: string[] | undefined;
        /** メモの3D座標 */
        kartePositions: KartePosition[] | undefined;
        /** 設置中のメモの3D座標 */
        temporaryKartePosition: KartePosition;
    };
    /** 変遷プール */
    readonly transitions: TransitionPool;
}

interface ObjectInfo {
    /** 主要オブジェクトに含まれるかどうか（該当オブジェクト・子オブジェクトか、それ以外かを識別するため） */
    inMainObject: boolean;
    /**
     * 割り当てられているマテリアル
     *
     * オブジェクトに直接属するメッシュが存在しない場合、`undefined`になります。
     * (グループとしてあるけど、所属メッシュ全部に名前がついているときとか)
     */
    material: THREE.MeshStandardMaterial | undefined;
    /** メッシュ or グループ */
    object: THREE.Object3D;
}

/**
 * シーンを破棄します。
 *
 * @param scene 破棄するシーン
 */
function disposeScene(scene: THREE.Group): void {
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
            disposeMesh(child);
        }
    });
}

/**
 * メッシュを破棄します。
 *
 * @param mesh 破棄するメッシュ
 */
function disposeMesh(mesh: THREE.Mesh): void {
    mesh.geometry.dispose();
    disposeMaterial(mesh.material);
}

/**
 * マテリアルを破棄します。
 *
 * @param material 破棄するマテリアル
 */
function disposeMaterial(material: THREE.Material | THREE.Material[]): void {
    if (Array.isArray(material)) {
        material.forEach(disposeMaterial);
        return;
    }

    // マテリアルを破棄する
    material.dispose();

    // テクスチャを破棄する
    // (今のところ Texture を複数の Material で共有していないのでここで)
    // (Material の派生クラス毎にいろいろなテクスチャがあり得るので走査する)
    for (const value of Object.values(material)) {
        if (value instanceof THREE.Texture) {
            value.dispose();
        }
    }
}

/**
 * 今のオブジェクトの認知した名前を取得します。
 *
 * `mainObjectName`と`clickableObjectNames`のどちらにも含まれない名前は認知せず
 * 無視します。この場合、親の名前 (`parentObjectName`) を引き継ぎます。
 * (親の名前を引き継いだ場合、親の一部として扱われ、親とマテリアルを共有します)
 *
 * @param mainObjectName 主要オブジェクトの名前
 * @param clickableObjectNames クリック可能なオブジェクトの名前
 * @param parentObjectName 親オブジェクトの認知している名前
 * @param thisObjectName 今のオブジェクトの名前
 * @returns 今のオブジェクトの認知した名前
 */
function calcCognitiveObjectName(
    mainObjectName: string | undefined,
    clickableObjectNames: ReadonlySet<string>,
    parentObjectName: string | undefined,
    thisObjectName: unknown,
): string | undefined {
    if (
        typeof thisObjectName === "string" &&
        (thisObjectName === mainObjectName ||
            clickableObjectNames.has(thisObjectName))
    ) {
        return thisObjectName;
    }

    return parentObjectName;
}

/**
 * 指定子棚前のオブジェクトの色を計算します。
 *
 * - 注目中 ....... `theme.focusColor`
 * - 名無し or 主要オブジェクトの兄弟オブジェクト ....... `theme.trivialColor`
 * - その他全て ... `theme.mainColor`
 *
 * @param theme テーマ
 * @param focusedObjectName 注目しているオブジェクトの名前
 * @param objectName 今のオブジェクトの名前
 * @returns 今のオブジェクトの色
 */
function calcMaterialColor({
    theme,
    focusedObjectName,
    objectName,
    inMainObject,
    isWarning,
}: {
    theme: Theme;
    focusedObjectName: string | undefined;
    objectName: string | undefined;
    inMainObject: boolean | undefined;
    isWarning: boolean | undefined;
}): Theme.Color {
    const baseColor = inMainObject
        ? isWarning
            ? theme.warningColor
            : theme.mainColor
        : isWarning
        ? theme.warningTrivialColor
        : theme.trivialColor;

    if (focusedObjectName === undefined || objectName !== focusedObjectName) {
        return baseColor;
    }

    // 選択色と合成
    const { color: c1, opacity } = baseColor;
    const { color: c2, opacity: s } = theme.focusColor;
    const blend = new THREE.Color(c1).lerp(new THREE.Color(c2), s / (0.5 + s));

    return { color: `#${blend.getHexString()}`, opacity };
}

/**
 * 新しい {@link THREE.MeshStandardMaterial} インスタンスを作成します。
 *
 * @param color 作成するマテリアルの色
 * @returns 作成したマテリアル
 */
function createMaterial(color: Theme.Color): THREE.MeshStandardMaterial {
    return new THREE.MeshStandardMaterial({
        color: color.color,
        opacity: color.opacity,
        transparent: true,
        side: THREE.DoubleSide,
    });
}

/**
 * マテリアルの色を設定します。
 *
 * @param transitions 変遷プール
 * @param material 設定先のマテリアル
 * @param value 設定する色
 */
function setColor(
    transitions: TransitionPool,
    material: THREE.MeshStandardMaterial | undefined,
    value: Theme.Color,
): void {
    if (material === undefined) {
        return;
    }

    transitions.setColor({
        id: `${material.uuid}:color`,
        target: material.color,
        value: value.color,
    });
    transitions.setMaterialOpacity({
        id: `${material.uuid}:opacity`,
        target: material,
        value: value.opacity,
    });
}
