/**
 * 一定周期で `tick` イベントを発火する振動子クラスです。
 *
 * `tick` は 1 フレームの描画処理に利用されます。
 */
export class Quartz extends EventTarget {
    readonly #internal: Internal = {
        state: { timer: 0 },
    };

    /**
     * 破棄します。
     */
    dispose(): void {
        const { state } = this.#internal;
        cancelAnimationFrame(state.timer);
        state.timer = 0;
    }

    /**
     * `tick` の発行を一時停止します。
     */
    pause(): void {
        const { state } = this.#internal;
        cancelAnimationFrame(state.timer);
        state.timer = 0;
    }

    /**
     * `tick` の発行を再開します。
     */
    resume(): void {
        const { state } = this.#internal;
        if (state.timer === 0) {
            const tick = () => {
                state.timer = requestAnimationFrame(tick);
                this.dispatchEvent(new Event("tick"));
            };

            state.timer = requestAnimationFrame(tick);
        }
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

/**
 * {@link Quartz} の内部データ
 */
interface Internal {
    /** 内部状態 */
    readonly state: {
        /** 次のチックを呼び出すタイマー */
        timer: number;
    };
}
