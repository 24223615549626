/**
 * {@link ObjectEvent} の初期化パラメーター
 */
export interface ObjectEventInit extends EventInit {
    /**
     * イベントが発生したオブジェクトの名前
     */
    readonly objectName: string;
}

/**
 * 3D モデル内のオブジェクトに関するイベント
 */
export class ObjectEvent extends Event {
    /**
     * イベントが発生したオブジェクトの名前
     */
    readonly objectName: string;

    /**
     * 新しい {@link ObjectEvent} インスタンスを初期化します。
     *
     * @param type イベント種別
     * @param eventInitDict 初期化パラメーター
     */
    constructor(type: string, eventInitDict: ObjectEventInit) {
        const { objectName, ...restInit } = eventInitDict;
        super(type, restInit);
        this.objectName = objectName;
    }
}
