import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { debug } from "./debug.js";
import { DRACOLoader } from "./draco-loader.js";

/**
 * 3D モデル ファイルを管理するクラスです。
 */
export class Resource {
    readonly #internal: Internal = {
        gltfLoader: createGLTFLoader(),
        state: { currentUrl: undefined },
    };

    /**
     * 破棄します。
     */
    dispose(): void {
        const { state } = this.#internal;

        state.currentUrl = undefined;
    }

    /**
     * 指定 URL のモデルを読み込みます。
     *
     * @param url モデルの URL
     * @param onProgress 読込の進捗状況が変化したときに呼ばれるコールバック関数
     */
    async load(
        url: string,
        onProgress: (event: ProgressEvent) => void,
    ): Promise<THREE.Group> {
        const { gltfLoader, state } = this.#internal;

        state.currentUrl = url;

        const startTick = performance.now();
        try {
            const scene = await loadGLTF(url, gltfLoader, (progress) => {
                if (state.currentUrl === url) {
                    onProgress(progress);
                }
            });
            if (state.currentUrl === url) {
                debug(
                    "3D モデル読込にかかった時間: %oms\n%o",
                    Math.round(performance.now() - startTick),
                    scene,
                );
                return scene;
            }
        } catch (error: unknown) {
            if (state.currentUrl === url) {
                console.error(error);
                throw new Error("3D モデルの読込に失敗しました。");
            }
        }

        debug("別のモデルに遷移済みであるため、無視します (%s)", url);
        throw new Error("canceled");
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

/**
 * {@link Resource} の内部データ
 */
interface Internal {
    /** ローダー */
    readonly gltfLoader: GLTFLoader;
    /** 状態 */
    readonly state: {
        /** 現在のモデルのURL */
        currentUrl: string | undefined;
    };
}

/** キャッシュ */
const Cache = new Map<string, Promise<THREE.Group>>();

/**
 * {@link GLTFLoader} を作成します。
 *
 * このとき、DRACO圧縮をサポートするために追加の設定をします。
 *
 * @returns 作成した {@link GLTFLoader} インスタンス
 */
function createGLTFLoader(): GLTFLoader {
    const loader = new GLTFLoader();
    const draco = new DRACOLoader();

    draco.setDecoderPath(
        "https://www.gstatic.com/draco/versioned/decoders/1.5.3/",
    );
    loader.setDRACOLoader(draco);

    return loader;
}

/**
 * 3D モデルを読み込みます。
 *
 * キャッシュにある場合はそれを使います。
 * 無ければ新しく読み込んでキャッシュします。
 *
 * @param url ファイルの URL
 * @param loader ローダー
 * @param onProgress 進捗報告関数
 * @returns 読み込んだ 3D モデル
 */
function loadGLTF(
    url: string,
    loader: GLTFLoader,
    onProgress?: ((event: ProgressEvent<EventTarget>) => void) | undefined,
): Promise<THREE.Group> {
    const cacheKey = toCacheKey(url);

    // キャッシュにあればそれで確定
    const cachedPromise = Cache.get(cacheKey);
    if (cachedPromise) {
        // アクセス順に列挙するために順番入れ替え
        Cache.delete(cacheKey);
        Cache.set(cacheKey, cachedPromise);
        return cachedPromise;
    }

    // ロードする
    const loadPromise = loader
        .loadAsync(url, onProgress)
        .then((gltf) => gltf.scene);

    // キャッシュする
    Cache.set(cacheKey, loadPromise);
    loadPromise.catch(() => {
        Cache.delete(cacheKey);
    });

    // キャッシュが増えてきたら古いエントリを削除する
    deleteOldCache(Cache);

    return loadPromise;
}

/**
 * URL に対応するキャッシュ キーを取得します。
 *
 * S3 URL はクエリパラメーターに時刻を含む認証情報を持っており、同じファイルでも
 * タイミングによって異なる URL になります。
 * そのため、ここではクエリパラメーターを削除した部分をキャッシュ キーとしていま
 * す。
 *
 * @param urlText URL
 * @returns その URL のためのキャッシュキー
 */
function toCacheKey(urlText: string): string {
    const url = new URL(urlText);
    return url.origin + url.pathname;
}

/**
 * キャッシュが増えてきたら古いキャッシュ エントリを削除します。
 *
 * @param cache キャッシュ
 */
function deleteOldCache(cache: Map<string, Promise<THREE.Group>>): void {
    if (cache.size < 16) {
        return;
    }

    let count = 4;
    for (const key of cache.keys()) {
        if (--count < 0) {
            break;
        }

        cache.delete(key);
    }
}
