/**
 * キー毎に管理される待ち行列です。
 */
export class KeyingQueue {
    readonly #internal: Internal = {
        state: { currentKey: "", nextKey: "", queue: [] },
    };

    /**
     * 現在のキーを取得します。
     */
    get currentKey(): string {
        return this.#internal.state.currentKey;
    }

    /**
     * 指定したキーのアクション・キューを全て実行します。
     *
     * ただし、キーが期待される次のキーと異なった場合は実行せずに破棄します。
     *
     * @param key 新たなキー
     */
    activate(key: string): void {
        const { state } = this.#internal;

        if (key === state.currentKey) {
            return;
        }

        // キューを実行する
        if (key === state.nextKey) {
            for (const action of state.queue) {
                action();
            }
        }
        state.currentKey = key;
        state.nextKey = "";
        state.queue.length = 0;
    }

    /**
     * アクションを待ち行列に追加します。
     *
     * キーが現在のキーだった場合は、アクションを即座に実行します。この場合、
     * 待ち行列には追加しません。
     *
     * キーが期待される次のキーと異なる場合、既存の待ち行列を破棄して、期待され
     * る次のキーを更新します。
     *
     * @param key キー
     * @param action アクション
     */
    enqueue(key: string, action: () => void): void {
        const { state } = this.#internal;

        if (key === state.currentKey) {
            action();
            return;
        }

        if (key !== state.nextKey) {
            state.queue.length = 0;
            state.nextKey = key;
        }
        state.queue.push(action);
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

/**
 * {@link KeyingQueue} の内部データ
 */
interface Internal {
    /** 内部状態 */
    readonly state: {
        /** 現在のキー */
        currentKey: string;
        /** 次のキー */
        nextKey: string;
        /** 待ち行列 */
        readonly queue: (() => void)[];
    };
}
