import * as THREE from "three";

/**
 * {@link LoadEvent} の初期化パラメーター
 */
export interface LoadEventInit extends EventInit {
    /**
     * 読み込んだモデル
     */
    scene: THREE.Group;
    /**
     * 読み込んだモデルの URL
     */
    url: string;
}

/**
 * モデルの読込が完了したことを伝えるイベント
 */
export class LoadEvent extends Event {
    readonly scene: THREE.Group;
    readonly url: string;

    constructor(type: string, eventInitDict: LoadEventInit) {
        const { scene, url, ...restInit } = eventInitDict;
        super(type, restInit);
        this.scene = scene;
        this.url = url;
    }
}
