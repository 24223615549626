export namespace Theme {
    /** 色と不透明度 */
    export interface Color {
        /** 色 */
        color: string;
        /** 不透明度 */
        opacity: number;
    }
}

/** テーマの色や透明度などを定義するインターフェイス */
export interface Theme {
    /** フォーカスしているモデルの色 */
    focusColor: Theme.Color;
    /** モデルの色 */
    mainColor: Theme.Color;
    /** 周辺 (親や兄弟) モデルの色 */
    trivialColor: Theme.Color;
    /** 警告発生時のモデルの色 */
    warningColor: Theme.Color;
    /** 警告発生中の周辺モデルの色 */
    warningTrivialColor: Theme.Color;
}

/** デフォルトの色 */
export const DefaultTheme: Theme = {
    focusColor: { color: "#fff", opacity: 1.0 },
    mainColor: { color: "#ccc", opacity: 1.0 },
    trivialColor: { color: "#ccc", opacity: 0.125 },
    warningColor: { color: "#ccc", opacity: 1.0 },
    warningTrivialColor: { color: "#ccc", opacity: 0.25 },
};
