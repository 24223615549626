import { KarteScreenPosition, NodeScreenPosition } from "./three";

/**
 * {@link RenderEvent} の初期化パラメーター
 */
export interface RenderEventInit extends EventInit {
    /**
     * 投影後のNode情報
     */
    nodeScreenPositions: NodeScreenPosition[];
    /**
     * 投影後のKarte座標（登録済みメモ）
     */
    karteScreenPositions: KarteScreenPosition[];
    /**
     * 投影後のKarte座標（位置確認中メモ）
     */
    temporaryKarteScreenPosition: KarteScreenPosition;
}

/**
 * モデルの読込が完了したことを伝えるイベント
 */
export class RenderEvent extends Event {
    readonly nodeScreenPositions: NodeScreenPosition[];
    readonly karteScreenPositions: KarteScreenPosition[];
    readonly temporaryKarteScreenPosition: KarteScreenPosition;

    constructor(type: string, eventInitDict: RenderEventInit) {
        const {
            nodeScreenPositions,
            karteScreenPositions,
            temporaryKarteScreenPosition,
            ...restInit
        } = eventInitDict;
        super(type, restInit);
        this.nodeScreenPositions = nodeScreenPositions;
        this.karteScreenPositions = karteScreenPositions;
        this.temporaryKarteScreenPosition = temporaryKarteScreenPosition;
    }
}
